import {Link, type LinkProps} from "@remix-run/react";
import {type ReactNode} from "react";
import {type ButtonProps, buttonVariants} from "#app/components/ui/button.tsx";
import { cn } from "#app/utils/misc.js";

export const ButtonLink = (
  {
    to,
    children,
    className,
    variant = "default",
    size = "default",
    ...props
  }: LinkProps & ButtonProps & {
    to: string
    children: ReactNode
    variant?: ButtonProps["variant"]
    size?: ButtonProps["size"]
  }
) => {
  return (
    <Link to={to} className={cn(buttonVariants({variant: variant, size: size}), className)} {...props}>
      {children}
    </Link>
  )
}